import Plausible from 'plausible-tracker'

export const useTracking = () => {
  const { trackPageview, trackEvent } = Plausible({
    trackLocalhost: true,
    domain: 'iliad.fr',
    apiHost: 'https://pm.web.proxad.net'
  })

  return { trackPageview, trackEvent }
}
